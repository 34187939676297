export const FirebaseConfig = {
	"projectId": "exicube-delivery",
	"appId": "1:970730021168:web:4fa3b2ad1e32ae5686a8f5",
	"databaseURL": "https://exicube-delivery-default-rtdb.firebaseio.com",
	"storageBucket": "exicube-delivery.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDyPXg5TFR06vOBV4nm3euw84K7Hh8JZ94",
	"authDomain": "exicube-delivery.firebaseapp.com",
	"messagingSenderId": "970730021168",
	"measurementId": "G-Y4V11KZNH8"
};